export default {
  keycloak: {
    url: "https://sso.diga-lab-dev.apat-dtb.com/auth",
    realm: "hub-connect",
    clientId: "diga-lab-hub",
  },
  userClient: {
    url: "https://user-api.diga-lab-stage.apat-dtb.com",
  },
  baseURL: "https://im-api.diga-lab-dev.apat-dtb.com",
  fhirServerUrl: "https://fhir.diga-lab-dev.apat-dtb.com",
  easyUrl: "https://pm-easy.diga-lab-dev.apat-dtb.com",
  caseManagement: "https://signup.api.comjoo.com",
  appLinks: {
    android: "https://play.google.com/store/apps/details?id=com.comjoo.easy",
    ios: "https://apps.apple.com/de/app/comjoodoc-easy/id1485413971",
  },
};
