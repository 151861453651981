export default {
  keycloak: {
    url: "https://sso.diga-lab-dev.apat-dtb.net/auth",
    realm: "diga-lab-hub",
    clientId: "hub-connect",
  },
  userClient: {
    url: "https://user-api.diga-lab-dev.apat-dtb.com",
  },
  baseURL: "https://im.diga-lab-dev.apat-dtb.net.net",
  fhirServerUrl: "https://test-server.fhir.medworx.io",
  easyUrl: "https://pm-easy.diga-lab-dev.apat-dtb.net",
  caseManagement: "https://signup.diga-lab-dev.apat-dtb.net",
  appLinks: {
    android: "https://play.google.com/store/apps/details?id=com.comjoo.easy",
    ios: "https://apps.apple.com/de/app/comjoodoc-easy/id1485413971",
  },
};
