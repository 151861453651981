import { Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import './Imprint.scss';

const { Title, Text } = Typography;

const Imprint: FC<any> = (props: any): any => {
 

  return (
    <div className="about-wrapper" >
    <Row type="flex" justify="center">
    <Col xs={23} sm={23} xl={22} >
      <Title level={1} style={{margin:40}}>
        Impressum
      </Title>

      <Col className="content-wrapper dtb-long-text dtb-imprint-text-layout"  style={{background:'#fff', padding:20}}>
        <div className="avoid-break">
          <Text strong>
            DTB Gesellschaft für digitale Therapiebegleitung mbH
            <br />
          </Text>
          <Text>
            Otto-Schott-Straße 15 <br />
            07745 Jena <br /> <br />
          </Text>
        </div>
        <div className="avoid-break">
          <Text strong>
            Geschäftsführer: <br />
          </Text>
          <Text>
            Ingmar Wegner <br />
            <br />
            Telefon: 03641 62840284 <br />
            Telefax: 03641 628400320 <br />
            E-Mail:{" "}
            <a href="mailto:info@digitale-therapiebegleitung.de">
              info@digitale-therapiebegleitung.de
            </a>{" "}
            <br />
            <br />
          </Text>
        </div>
        <div className="avoid-break">
          <Text strong>
            Steuer &amp; Registereintrag <br />
          </Text>
          <Text>
            Ust-IDNr. DE 340 985 477 <br />
            Steuer-Nr. 162/107/06880 <br />
            HRB 508202 <br />
            Amtsgericht Jena <br />
            <br />
          </Text>
        </div>
        <div className="avoid-break">
          <Text strong>
            Datenschutzbeauftragter: <br />
          </Text>
          <Text>
            Kai-Uwe Marquardt <br />
            Telefon:{" "}
            <a href="tel: 49123456789" rel="noopener noreferrer">
              01511 6261369
            </a>
            <br />
            E-Mail:{" "}
            <a href="mailto:datenschutz-dtb@digitale-therapiebegleitung.de">
              datenschutz-dtb@digitale-therapiebegleitung.de
            </a>{" "}
            <br />
            <br />
          </Text>
        </div>
        <div className="avoid-break">
          <Text strong>
            Compliance-Officer: <br />
          </Text>
          <Text>
            Frank Sürmann <br />
            Telefon:{" "}
            <a href="tel: 49123456789" rel="noopener noreferrer">
              0621 1250860
            </a>
            <br />
            E-Mail:{" "}
            <a href="mailto:compliance@convales.de">
              compliance@convales.de
            </a>{" "}
            <br />
            <br />
          </Text>
        </div>
        <div className="avoid-break">
          <Text strong>
            Zweckbestimmung
            <br />
          </Text>
          <Text>
            Der APAT DTB Proto ist ein digitaler Therapiebegleiter zur
            aktiven Unterstützung des Therapiemanagements von Patienten mit
            einer ambulanten parenteralen Antiinfektivatherapie (APAT).
            <br />
            <br />
          </Text>

          <Text>
            Der APAT DTB Proto verbessert das Therapiemanagement durch:
            <br />
            <ul>
              <li>
                applikationszeitpunkt- und medikationsbezogene
                Therapiepläne,
              </li>
              <li>
                therapieindividuelle Erinnerungsfunktionen zur Applikation,
              </li>
              <li>
                therapieindividuelle Anzeige von Nebenwirkungen,
                Vitalwerten, Anwendungs- und Einnahmehinweise sowie wichtige
                Informationen zur Medikation,
              </li>
              <li>
                die Dokumentation von Applikationen, Nebenwirkungen und
                Vitalwerten.
              </li>
            </ul>
            <br />
          </Text>
        </div>
      </Col>
    </Col>
  </Row>
  </div>
  );
};

export default Imprint;
